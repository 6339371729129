'use strict';
jQuery(document).ready(function ($) {
  $('#scroll-down').click(function () {
    $('html,body').animate(
      {
        scrollTop: $('.grid-container.home.section-two.fluid').offset().top,
      },
      'slow'
    );
  });

  $('#home-modal').foundation('open');
  $('#home-modal .close-button').on('click', function () {
    $('#home-modal').foundation('close');
  });
});
